import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Router and Route components
import ShowSchedules from './pages/BusSchedule';
import PalestineSummary from './pages/palestine'; // Ensure the correct path to PalestineSummary
import { Analytics } from "@vercel/analytics/react";
import { initGA, logPageView } from './analytics'; // Import your Google Analytics functions
import Landing from './pages/Maintenance';

const App: React.FC = () => {

  useEffect(() => {
    initGA(); // Initialize Google Analytics
    logPageView(); // Log initial page view
  }, []);

  return (
    <Router>
      <div>
        <Routes>
        <Route path="/" element={<ShowSchedules />} />
          {/*
        <Route path="*" element={<Landing />} />
           
          <Route path="/" element={<ShowSchedules />} />
          <Route path="/palestine-summary" element={<PalestineSummary />} />
          */}
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
};

export default App;

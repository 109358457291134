import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from '../firebase-config'; // Import Firestore functions
import { initGA, logPageView } from '../analytics'; // Import GA functions

interface Schedule {
  busNumber: string;
  departure: string;
  route: string;
  status: string;
  buses: number;
  day: string; // Add day field
}

const ShowSchedules: React.FC = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [todaySchedules, setTodaySchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDay, setSelectedDay] = useState<string>('Monday');
  const [selectedRoute, setSelectedRoute] = useState<string>('Ruqayyah');

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const routes = ['Ruqayyah', 'Salahuddin'];

  useEffect(() => {
    initGA();
    logPageView();

    const fetchSchedules = async () => {
      try {
        const schedulesRef = collection(db, 'schedulebus');
        const schedulesSnapshot = await getDocs(schedulesRef);
        const schedulesList = schedulesSnapshot.docs.map(doc => ({
          ...(doc.data() as Omit<Schedule, 'day'>),
          day: doc.data().day as string,
        }));
        setSchedules(schedulesList);
      } catch (error) {
        console.error("Error fetching schedules: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchedules();
  }, []);

  useEffect(() => {
    const filterTodaySchedules = () => {
      const currentDay = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kuala_Lumpur",
        weekday: "long",
      });
      const currentTime = new Date().toLocaleTimeString("en-US", {
        timeZone: "Asia/Kuala_Lumpur",
        hour12: false,
      });

      const filteredSchedules = schedules.filter(
        (schedule) => schedule.day === currentDay && schedule.departure > currentTime
      );

      const nextSchedules: { [key: string]: Schedule } = {};
      filteredSchedules.forEach((schedule) => {
        const routeKey = schedule.route;
        if (!nextSchedules[routeKey] || nextSchedules[routeKey].departure > schedule.departure) {
          nextSchedules[routeKey] = schedule;
        }
      });

      const nextSchedulesArray = Object.values(nextSchedules).sort((a, b) =>
        a.departure.localeCompare(b.departure)
      );

      setTodaySchedules(nextSchedulesArray);
    };

    if (!loading) {
      filterTodaySchedules();
    }
  }, [schedules, loading]);

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDay(e.target.value);
    logPageView();
  };

  const handleRouteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRoute(e.target.value);
    logPageView();
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center relative"
      style={{
        backgroundImage: `url('https://tronova.azmiproductions.com/img/backy.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Loading Overlay */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
          <img 
            src="https://tronova.azmiproductions.com/img/smart.png" 
            alt="Loading Logo" 
            className="mx-auto w-32 mb-1 opacity-100 animate-fade"
          />
         
        </div>
        
      )}

      {/* Main Content */}
      {!loading && (
        <div className="bg-black bg-opacity-20 rounded-lg w-full p-10 h-full shadow-lg">
          <img 
            src="https://tronova.azmiproductions.com/img/smart.png" 
            alt="Smart Shuttle Logo" 
            className="mx-auto w-32 mb-1"
          />
          <h1 className="text-2xl font-bold text-center text-white ">Smart Schedules</h1>
          <h1 className="text-sm font-bold text-center text-white mb-8">Rotate phone for better experience</h1>
          <div className="items-center text-center align-center">
            <button
              onClick={() => window.location.href = 'https://smart-response.azmiproductions.com'}
              className="bg-blue-800 uppercase text-base hover:bg-blue-900 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 transform hover:scale-105"
            >
              Click here to report shuttle issues - Smart Response
            </button>
          </div>

          {/* Today's Schedule Cards */}
          <div className="mt-8">
  <h2 className="text-lg font-semibold text-center text-gray-100 mb-8">
    Next Departure Scheduled for{" "}
    <span className="text-gray-100">
      {new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kuala_Lumpur",
        weekday: "long",
      })}
    </span>
  </h2>
  {todaySchedules.length > 0 ? (
    <div className="space-y-6">
      {todaySchedules.map((schedule, index) => (
        <div
          key={index}
          className="bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-2xl shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-gray-500"
        >
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-100">
                {schedule.route}
              </h3>
              <p className="text-sm text-gray-400">{schedule.day}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-400">Departure:</p>
              <p className="text-2xl font-bold text-gray-400">
                {schedule.departure}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-2">
            <span className=" py-1 font-medium text-white text-sm rounded-full shadow-sm">
              {schedule.busNumber}
            </span>
            <span className="text-sm font-medium text-gray-400">
              {schedule.buses} {schedule.buses > 1 ? "Buses" : "Bus"}
            </span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p className="text-gray-200 text-center text-lg mt-4">
      No more buses for today.
    </p>
  )}
</div>

          <div className="mt-6">
            <h2 className="text-base text-center text-white ">
              Shuttle Schedule
            </h2>
          </div>
          {/* Day and Route Pickers */}
          <div className="flex justify-center space-x-4 mt-10 mb-6">
            <select
              className="px-4 py-2 rounded bg-gray-800 text-white"
              value={selectedDay}
              onChange={handleDayChange}
            >
              {daysOfWeek.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>

            <select
              className="px-4 py-2 rounded bg-gray-800 text-white"
              value={selectedRoute}
              onChange={handleRouteChange}
            >
              {routes.map((route) => (
                <option key={route} value={route}>
                  {route}
                </option>
              ))}
            </select>
          </div>

          <div className="overflow-auto h-full">
  <table className="min-w-full bg-gray-900 rounded-lg shadow-md overflow-hidden">
    <thead>
    <tr className="bg-gradient-to-r from-blue-950 via-blue-600 to-gray-950 border-b text-white border-gray-600">
        <th className="px-6 py-4 text-center text-sm font-semibold uppercase tracking-wide">Day</th>
        
       {/* <th className="px-6 py-4 text-center text-sm font-semibold uppercase tracking-wide">Bus Type</th>*/}
        <th className="px-6 py-4 text-center text-sm font-semibold uppercase tracking-wide">Departure</th>
        <th className="px-6 py-4 text-center text-sm font-semibold uppercase tracking-wide">Route</th>
        <th className="px-6 py-4 text-center text-sm font-semibold uppercase tracking-wide">Bus(es)</th>
      </tr>
    </thead>
    <tbody className="bg-gray-800 divide-y divide-gray-700">
      {schedules
        .filter(schedule => 
          (selectedDay === '' || schedule.day === selectedDay) && 
          (selectedRoute === '' || schedule.route === selectedRoute)
        )
        .sort((a, b) => a.departure.localeCompare(b.departure))
        .map((schedule, index) => (
          <tr key={index} className="hover:bg-gray-700 text-center transition duration-200">
            <td className="px-6 py-4 text-sm text-white">{schedule.day}</td>
            
           {/* <td className="px-6 py-4 text-sm font-medium text-white">{schedule.busNumber}</td>*/}
            <td className="px-6 py-4 text-sm text-white">{schedule.departure}</td>
            <td className="px-6 py-4 text-sm text-white">{schedule.route}</td>
            <td className="px-6 py-4 text-sm text-white">{schedule.buses}</td>
          </tr>
        ))}
    </tbody>
  </table>
</div>

        </div>
      )}
    </div>
  );
};

export default ShowSchedules;
